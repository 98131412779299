import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://btcada/<br />105.00 HNS<br />0.00 ETH<br />$0.95<br /><br />🥈 https://adagov/<br />99.92 HNS<br />0.00 ETH<br />$0.90<br /><br />🥉 https://setmatch/<br />78.00 HNS<br />0.00 ETH<br />$0.70<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://animate/<br />15,000.00 HNS<br />0.04 ETH<br />$135.51<br /><br />🥈 https://blink/<br />10,000.00 HNS<br />0.03 ETH<br />$90.34<br /><br />🥉 https://sneakershop/<br />3,711.00 HNS<br />0.01 ETH<br />$33.53<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▏▁▁▁▁▁▁▁▁▁▁▁ ] 31.35%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▏▁▁▁▁▁▁▁▁ ] 47.9%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6541)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)